import types from './registration.types'
import initialState from './registration.state'
import head from 'lodash/head'
import orderBy from 'lodash/orderBy'

export default {
  [types.RESET_STATE]: (state) => {
    Object.assign(state, initialState())
  },

  [types.REGISTER_MEMBER_EXISTENCE_REQUEST]: (state) => {
    state.isFetching = true
  },
  [types.REGISTER_MEMBER_EXISTENCE_SUCCESS]: (
    state,
    {
      contactEmail,
      contactPhone,
      contactPhoneType,
      databasePreferredLanguageCd,
      primarySmsPhone,
      mfaType,
    }
  ) => {
    state.isFetching = false
    state.memberExists = true
    state.databasePreferredLanguageCd = databasePreferredLanguageCd
    state.email = contactEmail
    state.phone1.value = contactPhone
    state.phone1.type = contactPhoneType
    state.primarySmsPhone = primarySmsPhone
    state.mfaPreference = mfaType
  },
  [types.REGISTER_MEMBER_EXISTENCE_FAILURE]: (state) => {
    state.isFetching = false
  },

  [types.REGISTER_USERNAME_VALID_REQUEST]: (state) => {
    state.isFetching = true
  },
  [types.REGISTER_USERNAME_VALID_SUCCESS]: (state) => {
    state.isFetching = false
    state.usernameIsValid = true
  },
  [types.REGISTER_USERNAME_VALID_FAILURE]: (state) => {
    state.isFetching = false
  },

  [types.REGISTER_REQUEST]: (state) => {
    state.isFetching = true
  },
  [types.REGISTER_SUCCESS]: (state) => {
    state.isFetching = false
  },
  [types.REGISTER_FAILURE]: (state) => {
    state.isFetching = false
  },

  [types.POST_REGISTRATION_MEMBER_INFO_REQUEST]: (state) => {
    state.isFetching = true
  },
  [types.POST_REGISTRATION_MEMBER_INFO_SUCCESS]: (state) => {
    state.isFetching = false
  },
  [types.POST_REGISTRATION_MEMBER_INFO_FAILURE]: (state) => {
    state.isFetching = false
  },

  [types.REGISTER_ACCOUNT_INFO]: (state, { firstName, lastName, birthday }) => {
    state.firstName = firstName
    state.lastName = lastName
    state.birthday = birthday
  },
  [types.REGISTER_GUARDIAN_INFO]: (
    state,
    { guardianFirstName, guardianLastName, guardianBirthday }
  ) => {
    state.guardian.firstName = guardianFirstName
    state.guardian.lastName = guardianLastName
    state.guardian.dob = guardianBirthday
  },
  [types.REGISTER_MEMBER_INFO]: (state, { memberNumber }) => {
    state.memberNumber = memberNumber
  },
  [types.REGISTER_CONTACT]: (state, { email, phone1, phone2 }) => {
    state.email = email
    state.phone1.value = phone1.textValue
    state.phone1.type = phone1.selectValue
    state.phone2.value = phone2.textValue
    state.phone2.type = phone2.selectValue
  },
  [types.REGISTER_ADDRESS]: (
    state,
    { streetAddress1, streetAddress2, zipCode, city, state: stateName }
  ) => {
    state.street1 = streetAddress1
    state.street2 = streetAddress2
    state.zipCode = zipCode
    state.city = city
    state.state = stateName
  },
  [types.REGISTER_COMMUNICATION_PREFERENCES]: (
    state,
    communicationPreferences
  ) => {
    state.communicationPreferences = communicationPreferences
  },
  [types.REGISTER_MFA_PREFERENCE]: (state, { mfaPreference }) => {
    state.mfaPreference = mfaPreference
  },
  [types.REGISTER_PASSWORD_INFO]: (
    state,
    { username, password, preferredLanguageCd = 'EN' }
  ) => {
    state.username = username
    state.password = password
    state.preferredLanguageCd = preferredLanguageCd
  },
  [types.REGISTER_MFA_PREFERENCE_REQUEST]: (state) => {
    state.isFetching = true
  },
  [types.REGISTER_MFA_PREFERENCE_SUCCESS]: (state) => {
    state.isFetching = false
  },
  [types.REGISTER_MFA_PREFERENCE_FAILURE]: (state) => {
    state.isFetching = false
  },

  [types.LOOK_UP_NUMBERS_REQUEST]: (state) => {
    state.isFetching = true
  },
  [types.LOOK_UP_NUMBERS_SUCCESS]: (state, { lookedUpNumbers }) => {
    state.lookedUpNumbers = lookedUpNumbers.map((number) => {
      const json = JSON.stringify(number.phoneTypeJson)
      return { ...number, phoneTypeJson: json }
    })
    const importanceOrder = ['CELL', 'HOME', 'WORK']
    const primaryLookedUp = head(
      orderBy(
        state.lookedUpNumbers.filter((phone) => phone.isSmsCapable === true),
        [(phone) => importanceOrder.indexOf(phone.type)],
        ['asc']
      )
    )
    const primarySmsPhone = state.primarySmsPhone
    if (!primaryLookedUp) {
      state.primarySmsPhone = null
    } else if (
      !primarySmsPhone ||
      primaryLookedUp.contactNumber !== primarySmsPhone.contactNumber
    ) {
      state.primarySmsPhone = {
        contactNumber: primaryLookedUp.contactNumber,
        contactNumberCd: primaryLookedUp.type,
      }
    }
    state.isFetching = false
  },
  [types.LOOK_UP_NUMBERS_FAILURE]: (state) => {
    state.isFetching = false
  },

  [types.POST_REGISTER_WITH_MFA]: (state) => {
    state.isPostRegistrationWithMfa = true
  },

  [types.RESET_PASSWORD_REQUEST]: (state) => {
    state.resetPasswordIsFetching = true
  },
  [types.RESET_PASSWORD_SUCCESS]: (state) => {
    state.resetPasswordIsFetching = false
  },
  [types.RESET_PASSWORD_FAILURE]: (state) => {
    state.resetPasswordIsFetching = false
  },

  [types.CHANGE_PASSWORD_FROM_RESET_REQUEST]: (state) => {
    state.changePasswordFromResetIsFetching = true
  },
  [types.CHANGE_PASSWORD_FROM_RESET_SUCCESS]: (state) => {
    state.changePasswordFromResetIsFetching = false
  },
  [types.CHANGE_PASSWORD_FROM_RESET_FAILURE]: (state) => {
    state.changePasswordFromResetIsFetching = false
  },

  [types.CHECK_MEMBER_INFORMATION_REQUEST]: (state) => {
    state.checkMemberInformationIsFetching = true
  },
  [types.CHECK_MEMBER_INFORMATION_SUCCESS]: (state) => {
    state.checkMemberInformationIsFetching = false
  },
  [types.CHECK_MEMBER_INFORMATION_FAILURE]: (state) => {
    state.checkMemberInformationIsFetching = false
  },

  [types.SSO_REGISTRATION_INFO_REQUEST]: (state) => {
    state.isFetching = true
  },
  [types.SSO_REGISTRATION_INFO_SUCCESS]: (state) => {
    state.isFetching = false
  },
  [types.SSO_REGISTRATION_INFO_FAILURE]: (state) => {
    state.isFetching = false
  },
}

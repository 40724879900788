export default () => ({
  isFetching: false,
  isPostRegistrationWithMfa: false,
  resetPasswordIsFetching: false,
  changePasswordFromResetIsFetching: false,
  memberExists: false,
  usernameIsValid: false,
  firstName: null,
  lastName: null,
  birthday: null,
  memberNumber: null,
  email: null,
  phone1: {
    value: null,
    type: null,
  },
  phone2: {
    value: null,
    type: null,
  },
  lookedUpNumbers: [],
  primarySmsPhone: null,
  street1: null,
  street2: null,
  zipCode: null,
  city: null,
  state: null,
  communicationPreferences: [],
  mfaPreference: null,
  guardian: {},
  databasePreferredLanguageCd: null,
  username: '',
  password: '',
  preferredLanguageCd: '',
})

<template>
  <footer class="py-20">
    <nav class="container" role="navigation">
      <div class="flex items-center justify-center">
        <div
          class="mr-10 text-sm font-semibold"
          :class="{ 'text-white': isLight, 'text-grey-500': !isLight }"
        >
          {{
            $t('common.powered_by') +
              ` ${String.fromCharCode(0x24b8)} ${new Date().getFullYear()}`
          }}
        </div>
        <div class="ml-10" :class="{ 'text-white': isLight }">
          <ul class="flex items-center">
            <li class="mx-10">
              <router-link
                :to="{
                  name: 'public-privacy-document',
                  params: { title: $t('common.terms_link') },
                }"
                class="block text-sm font-semibold"
                :title="$t('common.terms')"
                target="_blank"
                >{{ $t('common.terms') }}</router-link
              >
            </li>
            <li class="mx-10">
              <router-link
                :to="{
                  name: 'public-privacy-document',
                  params: { title: $t('common.privacy_link') },
                }"
                class="block text-sm font-semibold"
                :title="$t('common.privacy')"
                target="_blank"
                >{{ $t('common.privacy') }}</router-link
              >
            </li>
            <li v-if="hasNonDiscrimination" class="mx-10">
              <a
                :href="`${$settings.S3_ROOT}/non-discrimination.pdf`"
                class="block text-sm font-semibold"
              >
                {{ $t('common.non_discrimination') }}
              </a>
            </li>
            <li>
              <button
                class="block text-sm font-semibold"
                @click="supportModalOpen = true"
              >
                {{ $t('common.contact_support') }}
              </button>
            </li>
            <li
              v-for="disclaimer in disclaimers"
              :key="disclaimer.id"
              class="mx-10"
            >
              <router-link
                :to="{
                  name: 'disclaimer',
                  params: { id: disclaimer.id },
                }"
                class="block text-sm font-semibold"
                :title="disclaimer.name"
              >
                {{ disclaimer.name }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <div
      v-if="hasCommonDisclaimer || footerDisclaimer"
      class="max-w-3xl mx-auto mt-20 text-xs text-center text-grey-500"
    >
      <p v-if="hasCommonDisclaimer">
        {{ $t('common.disclaimer', [$settings.NAME]) }}
      </p>
      <p v-if="footerDisclaimer" class="mt-5" v-html="footerDisclaimer" />
    </div>
    <Modal
      :active="supportModalOpen"
      closeOnBackDrop
      @close="closeSupportModal"
    >
      <ContactSupportModal @close="closeSupportModal" />
    </Modal>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex'
import { FEATURES } from '@platform-shared/constants'
import ContactSupportModal from '@components/common/ContactSupportModal'
import Modal from '@components/common/Modal'

export default {
  components: { ContactSupportModal, Modal },
  props: {
    authenticated: {
      type: Boolean,
      default: false,
    },
    isLight: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      supportModalOpen: false,
    }
  },
  computed: {
    ...mapGetters('client', ['hasFeature', 'footerDisclaimer']),
    ...mapGetters('member', ['disclaimers']),
    hasNonDiscrimination() {
      return this.hasFeature(FEATURES.NON_DISCRIMINATION)
    },
    hasCommonDisclaimer() {
      return this.hasFeature(FEATURES.DISCLAIMER)
    },
  },
  methods: {
    closeSupportModal() {
      this.supportModalOpen = false
    },
  },
}
</script>

<style lang="postcss" scoped></style>
